<template>
    <div class="deadline_tag">
        <template v-if="date">
            <a-tag 
                v-if="chechDate === 'expired'"
                class="m-0" 
                color="#f63f48">
                <span 
                    class="flex items-center" 
                    :class="wrapperClass">
                    <i class="fi fi-rr-clock-nine"></i>
                    {{$moment(date).format('DD.MM.YYYY в HH:mm')}}
                </span>
            </a-tag>
            <a-tag 
                v-if="chechDate === 'today'"
                class="m-0" 
                color="#fba140">
                <span 
                    class="flex items-center" 
                    :class="wrapperClass">
                    <i class="fi fi-rr-clock-nine"></i>
                    {{$moment(date).format('DD.MM.YYYY в HH:mm')}}
                </span>
            </a-tag>
            <span 
                v-if="chechDate === 'soon'"
                class="flex items-center"
                :class="wrapperClass">
                <i class="fi fi-rr-clock-nine"></i>
                {{$moment(date).format('DD.MM.YYYY в HH:mm')}}
            </span>
        </template>
        <template v-else>
            <a-tag>
                {{$t('task.no_time_limit')}}
            </a-tag>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        date: {
            type: [String, Number],
            default: null
        },
        taskStatus: {
            type: Object,
            required: true
        },
        wrapperClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        chechDate() {
            if(this.taskStatus?.code === 'completed')
                return 'soon'
            else {
                if(this.$moment().diff(this.date, 'minute') <= 1) {

                    let current = this.$moment(),
                        taskDate = this.$moment(this.date),
                        days = taskDate.diff(current, 'days')

                    if(days < 3 )
                        return 'today'
                    else
                        return 'soon'
                } else
                    return 'expired'
            }
        }
    }
}
</script>

<style lang="scss">
.deadline_tag{
    display: flex;
    align-items: center;
    i{
        margin-right: 5px;
    }
    .ant-tag{

    }
}
</style>
